import * as React from "react";
import vertexShader from "../shaders/vertex/nop.glsl";
import fragmentShader from "../shaders/fragment/blur.glsl";
import { Canvas, extend, MeshProps, ReactThreeFiber, ShaderMaterialProps, useFrame, useThree } from "@react-three/fiber";
import { Backdrop, Environment, OrbitControls, PerspectiveCamera, shaderMaterial, Sky, Stars, useFBX, useGLTF, useHelper } from "@react-three/drei";
import { Color, DoubleSide, MathUtils, ShaderMaterial, ShaderMaterialParameters, ShaderLib, UniformsUtils, Vector3, UniformsLib, FrontSide, WebGLRenderTarget, TextureDataType, LinearFilter, NearestFilter, Texture } from "three";
import { useWindowSize } from "../hooks/useWindowSize";
import { useGesture } from "@use-gesture/react";
import { VertexNormalsHelper } from 'three-stdlib'
import { EffectComposer } from "@react-three/postprocessing";
import { TintEffect } from "../shaders/post/TintEffect";
import { GaussianBlurEffectPass } from "../shaders/post/GassianBlurEffect";
import { GrassTile } from "../components/three/town/tiles/grassTile";
import { MooMoo } from "../components/three/town/chars/moomoo";
import { SobelEffectPass } from "../shaders/post/SobelEffect";
import { PostComposer } from "../shaders/post/PostComposer";


const builtinShader = ShaderLib.normal;

let uniforms = UniformsUtils.merge([builtinShader.uniforms, UniformsLib.lights, UniformsLib.common]);
uniforms.time = { value: 0 };
uniforms.lookatVector =  { value: new Vector3(0,0,0) };
uniforms.amplifier = { value: 10.0 };
uniforms.speed = { value: 2.0 };
uniforms.width = { value: 1200.0 };
uniforms.range = { value: 9.0 };
uniforms.pass = {value: 0};
uniforms.height = { value: 800.0 };
uniforms.screen = { value: new Texture() };
uniforms.color = { value: new Color("#ff0000") };
uniforms.waterAmplitude = { value: 5.0 };
uniforms.waterWaveLength = { value:  0.5 };
uniforms.waterSpeedAmplifier = { value: uniforms.waterWaveLength.value / 2};
uniforms.waterWaveCutoff = { value: 2.5 };

const ShaderPageContent : React.FC =() => {

    const { camera, scene } = useThree();

    const meshRef = React.useRef<any>();
    const materialRef = React.useRef<ShaderMaterialParameters & typeof uniforms>();

    // useHelper(meshRef, VertexNormalsHelper, 10.0, 0x0000ff);

    React.useEffect(() => {
        if(camera == null) return;
        camera.position.set(0, 10, 10);
    }, [camera]);

    useFrame((state) => {
        if(materialRef?.current?.uniforms == null) return;
        const vec = new Vector3();
        state.camera.lookAt(new Vector3(0,0,0));
        state.camera.getWorldDirection(vec);

        const buffer = new WebGLRenderTarget(state.size.width, state.size.height, {
            stencilBuffer: false,
            depthBuffer: false
        });
        const prevTarget = state.gl.getRenderTarget();
        state.gl.setRenderTarget(buffer);
        state.gl.render(state.scene, state.camera);
        state.gl.setRenderTarget(prevTarget);
        materialRef.current.uniforms.screen.value = buffer.texture;
        materialRef.current.uniforms.width.value = state.size.width;
        materialRef.current.uniforms.height.value = state.size.height;

        materialRef.current.uniforms.lookatVector.value = vec;
        materialRef.current.uniforms.time.value = state.clock.elapsedTime;
    });

    //const { nodes, materials } = useGLTF("/models/town/chars/moomoo.gltf");
    
    const knightFbx = useFBX("/models/test/knight.fbx");

    return (
        <>
            <mesh>
                <primitive object={knightFbx} />
                <meshStandardMaterial color={"#aa0000"} />
            </mesh>
        </>
    )
}

const ShaderPage : React.FC = () => {
    const bind = useGesture({
        onWheel: (state) => {
            console.log("wheel");
        }
    }, {
        wheel: {}
    }); 
    
    const { width, height } = useWindowSize();
    
    return (
        <div style={{width: width, height: height}}>
            <Canvas {...bind()} >
                {/* 
                    This breaks GaussianBlurEffectPass for some reason.
                    <color attach="background" args={["#ffffff"]} />
                 */}
                <OrbitControls target={[0,0,0]}  />
                <hemisphereLight intensity={1.0} color={"#ffffff"} groundColor={"#000000"} />


                <ShaderPageContent />

                {/* <Environment preset="apartment" ground /> */}

                <PerspectiveCamera makeDefault far={3000} />
                
                {/* <SobelEffectPass /> */}
                {/* <GaussianBlurEffectPass blur={3} /> */}

                 {/* <PostComposer>
                    <GaussianBlurEffectPass blur={12} density={2}/>
                    <SobelEffectPass />
                 </PostComposer> */}

            </Canvas>
        </div>
    
    );  
}

export default ShaderPage;